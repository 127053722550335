import React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import RouterLink from "components/RouterLink"
import { Link } from "gatsby"
import MuiLink from '@mui/material/Link';
import {
  GetStarted,
  Features,
  QuickStart,
  Services,
  Benefits,
  Hero,
	Products,
  Teaser
} from './components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



const IndexView = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const [hoverIndex, setHoverIndex] = React.useState(-1);
  const [focusIndex, setFocusIndex] = React.useState(-1);
	const isHover = (i)=>{
		if(hoverIndex==i){
			return true
		} else {
			if((hoverIndex == 2 || hoverIndex == 3 ) && (i == 2 || i == 3 )){
				return true
			} else if((hoverIndex == 5 || hoverIndex == 6 ) && (i == 5 || i == 6 )){
				return true
			} 
		}
		if(focusIndex==i){
			return true
		} else {
			if((focusIndex == 2 || focusIndex == 3 ) && (i == 2 || i == 3 )){
				return true
			} else if((focusIndex == 5 || focusIndex == 6 ) && (i == 5 || i == 6 )){
				return true
			} 
		}
		return false;
	}
	
	const tetris = [{
		initialSlide:1,
		title:'Flomesh GUI',
		src:'/img/home/1.svg',
		hoverSrc:'/img/home/1o.svg',
		width:'249px',
		className:'tetris-1'
	},{
		title:'FSM',
		initialSlide:2,
		src:'/img/home/2.svg',
		hoverSrc:'/img/home/2o.svg',
		width:'349px',
		className:'tetris-2'
	},{
		title:'Pipy',
		initialSlide:0,
		src:'/img/home/4-1.svg',
		hoverSrc:'/img/home/4-1o.svg',
		width:'350px',
		className:'tetris-4-1'
	},{
		title:'Pipy',
		initialSlide:0,
		src:'/img/home/4-2.svg',
		hoverSrc:'/img/home/4-2o.svg',
		width:'350px',
		className:'tetris-4-2'
	},{
		title:'',
		initialSlide:4,
		src:'/img/home/5.svg',
		hoverSrc:'/img/home/5o.svg',
		width:'450px',
		className:'tetris-5'
	},{
		title:'',
		initialSlide:5,
		src:'/img/home/6-1.svg',
		hoverSrc:'/img/home/6-1o.svg',
		width:'360px',
		className:'tetris-6-1'
	},{
		title:'',
		initialSlide:5,
		src:'/img/home/6-2.svg',
		hoverSrc:'/img/home/6-2o.svg',
		width:'360px',
		className:'tetris-6-2'
	},];
	
  const [open, setOpen] = React.useState(false);
	
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	
	try {
		if(location && location.hash == "#invitation" && !open){
			handleClickOpen()
		}
	} catch (e){}
  return (
      <Main >
				<Box
					sx={{
						backgroundImage: `linear-gradient(to bottom, ${alpha(
							theme.palette.background.paper,
							0,
						)}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
						backgroundRepeat: 'repeat-x',
						position: 'relative',
					}}
				>
					<Box paddingY={{ xs: 0, sm: '4rem', md: '8rem' }}>
						<Container>
							<Products initialSlide={tetris[focusIndex>=0?focusIndex:2].initialSlide}/>
						</Container>
						<Box
							sx={{
								display: { xs: 'none', sm: 'block' },
							}}
						>
							<Box
								display={'flex'}
								left={'50%'}
								top={0}
								position={'absolute'}
								sx={{
									zIndex: 1000,
									marginTop: '50px',
								}}
							>
								<Box className="tetris">
									{tetris.map((item, i) => (
										<Box
											key={i}
											component={'img'}
											src={isHover(i)?item.hoverSrc:item.src}
											alt={item.title}
											className={(isHover(i)?'hover ':'') + item.className}
											onClick={()=>{setFocusIndex(i)}}
											onMouseOut={()=>{setHoverIndex(-1)}}
											onMouseOver={()=>{setHoverIndex(i)}}
											sx={{
												borderRadius: 0,
												width:item.width
											}}
										/>
									))}
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{"Tips"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Would you apply to the free trial quota?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>Cancel</Button>
						<Button to="/contact" component={Link} autoFocus>
							Go
						</Button>
					</DialogActions>
				</Dialog>
      </Main>
  );
};

// <Container maxWidth={600}>
//   <QuickStart />
// </Container>
// <Container>
//   <Features />
// </Container>
export default IndexView;
